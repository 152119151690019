body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.header-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.site-logo {}

.site-logo img {
  max-width: 120px;
  display: inline-block;
}

.site-menu {}

.site-menu ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.site-menu ul li {}

.site-menu ul li a {
  display: inline-block;
  text-decoration: none;
  color: #000;
  font-size: 20px;
  padding: 15px 30px 18px;
  border-radius: 8px;
  line-height: 20px;
  transition: all 0.2s;
}

.site-menu ul li a:hover {
  background: #fb2056;
  color: #fff;
}

header.site-header {
  background: #fff;
  padding: 20px 0;
}

body {
  background: #f5f5f5;
}

.btn-primary {
  background: #d32f30 !important;
  border: none !important;
  outline: none !important;
  text-transform: uppercase;
  font-weight: bold;
  padding: 12px 35px;
  border-radius: 8px !important;
}

.main-body-area {
  padding: 50px 0;
}

.main-body-area select,
.main-body-area input[type="text"],
.main-body-area input[type="email"],
.main-body-area input[type="tel"],
.main-body-area input[type="url"],
.main-body-area input[type="file"],
.main-body-area input[type="password"],
.main-body-area input[type="number"] {
  outline: none !important;
  border: 1px solid #ddd !important;
  box-shadow: none !important;
  background: #fff !important;
  height: 53px !important;
  padding: 13px 17px;
}

.main-body-area select:focus,
.main-body-area input[type="text"]:focus,
.main-body-area input[type="email"]:focus,
.main-body-area input[type="tel"]:focus,
.main-body-area input[type="url"]:focus,
.main-body-area input[type="file"]:focus,
.main-body-area input[type="password"]:focus,
.main-body-area input[type="number"]:focus {
  border: 1px solid #555 !important;
}

.custom-images-display {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.custom-images-display img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 15px;
  margin-bottom: 15px;
  display: block;
  border: 1px solid #ddd;
  padding: 7px;
}

.custom-picker {
  position: relative;
}

.custom-picker label {
  position: relative;
}

.custom-picker span {
  display: block;
  background: #d32f30;
  color: #fff;
  width: 70px;
  height: 70px;
  line-height: 60px;
  text-align: center;
  font-size: 44px;
  border-radius: 50%;
  margin-bottom: 15px;
  cursor: pointer;
}

.main-body-area .border-remove {
  border: none !important;
  outline: none !important;
}

.image-plus-icon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

/*Loader Css*/
#loaderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 1000;
  /* Ensure it's on top */
  display: flex;
  justify-content: center;
  align-items: center;
}

#loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

/*Loader Css*/

.language-selection {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
}

.language-selection select {
  width: 100%;
  outline: none !important;
  border: 1px solid #ddd !important;
  box-shadow: none !important;
  background: #fff !important;
  height: 30px !important;
  padding: 0px 10px;
  max-width: 100px;
}

small.form-text.text-muted {
  display: flex;
  margin-top: 8px;
}

.custom-file-uploader{
  display: flex;
}

.custom-file-uploader {
  outline: none !important;
  border: 1px solid #ddd !important;
  box-shadow: none !important;
  background: #fff !important;
  height: 53px !important;
  padding: 5px 17px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  border-radius: 0.375rem;
}

.custom-file-uploader button {
  background: #ededed !important;
  color: #000 !important;
  line-height: 1.2;
  display: flex;
  font-size: 11px;
  padding: 14px 14px;
  max-width: 190px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 0px !important;
  font-weight: 500;
  border-right: 2px solid #ddd !important;
  margin-right: 10px !important;
  display: block;
}

.custom-file-uploader span {
  font-size: 13px !important;
  font-weight: 500;
  line-height: 1.5;
  padding: 10px 0;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}