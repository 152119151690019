.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-body-area {
  padding: 20px;
}

h1 {
  text-align: left;
}

.section-heading {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: left;
}

.form-label {
  display: block;
  text-align: left;
  margin-bottom: 0.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group-text {
  background: transparent;
}

.border-remove {
  border: none !important;
}

.custom-images-display {
  display: flex;
  flex-wrap: wrap;
}

.custom-images-display img {
  width: 80px;
  height: 80px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  padding: 7px;
}

.form-text.text-danger {
  color: red;
}

.custom-picker {
  display: flex;
  align-items: center;
}

.custom-picker span {
  background: #d32f30;
  color: white;
  font-size: 44px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hidden-file-input {
  position: absolute;
  left: -9999px; /* Move off-screen */
}

/* Prevent scrolling on #root when no-scroll class is applied */
#root.no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* Center loader overlay and spinner */
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure it covers the screen */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
